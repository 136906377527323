<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table :data="list">
        <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="uid" label="用户编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="nickname" label="用户昵称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="phone" label="手机号" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot="header">
            <y_choose_member @change="newOne">
              <el-button>添加管理</el-button>
            </y_choose_member>
          </template>
          <template v-slot="s">
            <el-button @click="del(s.row)" type="danger">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import y_choose_member from "@/components/user/YUserChoose";
export default {
  name: "manage",
  components: {y_choose_member},
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    del({id}){
      this.$u.api.card.manageDel({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    load() {
      this.$u.api.card.manageAll().then(res => {
        this.list = res;
      })
    },
    newOne({id}) {
      this.$u.api.card.manageAdd({id}).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>